























import { Component, Mixins } from "vue-property-decorator";
import AddAgentComponent from "./AddTournament.component";
@Component
export default class AddOperator extends Mixins(AddAgentComponent) {}
