var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayShow
    ? _c("div", [
        _c("div", [
          _c("h1", [
            _vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.H1_ADD_TOURNAMENT")) + " ")
          ]),
          _c("div", { staticClass: "section-group" }, [
            _c(
              "form",
              {
                attrs: { lass: "forms-sample", id: "form-agent" },
                on: {
                  submit: function($event) {
                    return _vm.sendForm($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(
                        _vm._s(_vm.$t("TOURNAMENT.GENERAL_INFORMATION")) + " "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label bold",
                          attrs: { for: "Username" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("TOURNAMENT.TIER_NAME")) + " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("v-select", {
                            attrs: {
                              id: "tierMame",
                              clearable: false,
                              options: _vm.tierNameOptions,
                              placeholder: _vm.$t("TOURNAMENT.TIER_NAME")
                            },
                            model: {
                              value: _vm.tierName,
                              callback: function($$v) {
                                _vm.tierName = $$v
                              },
                              expression: "tierName"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-4 col-form-label bold" },
                        [_vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.BUYIN")) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("v-select", {
                            attrs: {
                              id: "buyin",
                              clearable: false,
                              options: _vm.buyinOptions,
                              reduce: function(x) {
                                return x.value
                              },
                              placeholder: _vm.$t("TOURNAMENT.BUYIN")
                            },
                            model: {
                              value: _vm.buyin,
                              callback: function($$v) {
                                _vm.buyin = $$v
                              },
                              expression: "buyin"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-2 col-form-label bold",
                          attrs: { for: "startStackSize" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("TOURNAMENT.START_CHIPS")) + " "
                          ),
                          _c("span", { staticClass: "required" }, [_vm._v("★")])
                        ]
                      ),
                      _c("div", { staticClass: "col-10" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.startStacksize,
                              expression: "startStacksize"
                            }
                          ],
                          ref: "inputStartStackSize",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "startStackSize",
                            placeholder: _vm.$t("TOURNAMENT.START_CHIPS"),
                            autocomplete: "off",
                            name: _vm.$t("PLEASE_FILL_OUT_THIS_FIELD"),
                            oninvalid: "this.setCustomValidity(this.name)",
                            oninput: "this.setCustomValidity('')",
                            required: ""
                          },
                          domProps: { value: _vm.startStacksize },
                          on: {
                            keydown: function($event) {
                              return _vm.onlyNumber($event)
                            },
                            keypress: function($event) {
                              return _vm.onlyNumber($event)
                            },
                            keyup: function($event) {
                              _vm.startStacksize = _vm.formatNumber(
                                $event.target.value
                              )
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.startStacksize = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label bold",
                          attrs: { for: "Username" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("TOURNAMENT.MINIMUM_OF_PEOPLE")) +
                              " "
                          ),
                          _c("span", { staticClass: "required" }, [_vm._v("★")])
                        ]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.minimumOfPeople,
                              expression: "minimumOfPeople"
                            }
                          ],
                          ref: "inputUsername",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "Username",
                            placeholder: _vm.$t("TOURNAMENT.MINIMUM_OF_PEOPLE"),
                            autocomplete: "off",
                            name: _vm.$t("PLEASE_FILL_OUT_THIS_FIELD"),
                            oninvalid: "this.setCustomValidity(this.name)",
                            oninput: "this.setCustomValidity('')",
                            required: ""
                          },
                          domProps: { value: _vm.minimumOfPeople },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.minimumOfPeople = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-4 col-form-label bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("TOURNAMENT.MAXIMUM_OF_PEOPLE")) +
                              " "
                          ),
                          _c("span", { staticClass: "required" }, [_vm._v("★")])
                        ]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.maximumOfPeople,
                              expression: "maximumOfPeople"
                            }
                          ],
                          ref: "inputUsername",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "Username",
                            placeholder: _vm.$t("TOURNAMENT.MAXIMUM_OF_PEOPLE"),
                            autocomplete: "off",
                            name: _vm.$t("PLEASE_FILL_OUT_THIS_FIELD"),
                            oninvalid: "this.setCustomValidity(this.name)",
                            oninput: "this.setCustomValidity('')",
                            required: ""
                          },
                          domProps: { value: _vm.maximumOfPeople },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.maximumOfPeople = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label bold",
                          attrs: { for: "Username" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("TOURNAMENT.START_TIME")) + " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("date-picker", {
                            attrs: {
                              id: "startDate",
                              type: "datetime",
                              "input-class": "mx-input2 col-12",
                              clearable: false,
                              editable: false,
                              format: "YYYY-MM-DD HH:mm"
                            },
                            model: {
                              value: _vm.startTime,
                              callback: function($$v) {
                                _vm.startTime = $$v
                              },
                              expression: "startTime"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.$t("TOURNAMENT.SCHEDULE")))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row mb-4" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("TOURNAMENT.MINUTE")) + " "
                            )
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("TOURNAMENT.HOUR")) + " "
                            )
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("TOURNAMENT.DAY_OF_MONTH")) +
                                " "
                            )
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.MONTH")))
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("TOURNAMENT.DAY_OF_WEEK"))
                            )
                          ])
                        ])
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c(
                            "th",
                            { attrs: { scope: "row" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  placeholder: _vm.$t("TOURNAMENT.MINUTE"),
                                  clearable: false,
                                  options: _vm.minuteScheduleOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                model: {
                                  value: _vm.minSchedule,
                                  callback: function($$v) {
                                    _vm.minSchedule = $$v
                                  },
                                  expression: "minSchedule"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "th",
                            { attrs: { scope: "row" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  placeholder: _vm.$t("TOURNAMENT.HOUR"),
                                  clearable: false,
                                  options: _vm.hourScheduleOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                model: {
                                  value: _vm.hourSchedule,
                                  callback: function($$v) {
                                    _vm.hourSchedule = $$v
                                  },
                                  expression: "hourSchedule"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "th",
                            { attrs: { scope: "row" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  placeholder: _vm.$t(
                                    "TOURNAMENT.DAY_OF_MONTH"
                                  ),
                                  clearable: false,
                                  options: _vm.dayOfMonthScheduleOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                model: {
                                  value: _vm.dayOfMonthSchedule,
                                  callback: function($$v) {
                                    _vm.dayOfMonthSchedule = $$v
                                  },
                                  expression: "dayOfMonthSchedule"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "th",
                            { attrs: { scope: "row" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  placeholder: _vm.$t("TOURNAMENT.MONTH"),
                                  clearable: false,
                                  options: _vm.monthScheduleOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.handleMonthChange()
                                  }
                                },
                                model: {
                                  value: _vm.monthSchedule,
                                  callback: function($$v) {
                                    _vm.monthSchedule = $$v
                                  },
                                  expression: "monthSchedule"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "th",
                            { attrs: { scope: "row" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  placeholder: _vm.$t("TOURNAMENT.DAY_OF_WEEK"),
                                  clearable: false,
                                  options: _vm.dayOfWeekScheduleOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                model: {
                                  value: _vm.dayOfWeekSchedule,
                                  callback: function($$v) {
                                    _vm.dayOfWeekSchedule = $$v
                                  },
                                  expression: "dayOfWeekSchedule"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("tr", [
                          _c(
                            "th",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { scope: "row" }
                            },
                            [_vm._v(" 0 - 59 ")]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { scope: "row" }
                            },
                            [_vm._v(" 0 - 23 ")]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { scope: "row" }
                            },
                            [
                              _vm._v(
                                " 0 - " +
                                  _vm._s(
                                    _vm.dayOfMonthScheduleOptions.length > 0
                                      ? _vm.dayOfMonthScheduleOptions[
                                          _vm.dayOfMonthScheduleOptions.length -
                                            1
                                        ].value
                                      : 0
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { scope: "row" }
                            },
                            [_vm._v(" 1 - 12 ")]
                          ),
                          _c(
                            "th",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { scope: "row" }
                            },
                            [
                              _vm._v(" 0 - 6 "),
                              _c("br"),
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm.$t("TOURNAMENT.DAY_OF_WEEK_EXPLAIN")
                                  ) +
                                  ") "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-2 col-form-label bold",
                          attrs: { for: "Username" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("TOURNAMENT.CRONJOBS_MEANING")) +
                              " "
                          )
                        ]
                      ),
                      _c("div", { staticClass: "col-10" }, [
                        _c("div", [_vm._v(_vm._s(_vm.concatCron()))])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label bold",
                          attrs: { for: "Username" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("TOURNAMENT.DURATION_OF_SCHEDULE")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c("div", { staticClass: "col-7" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.durationOfSchedule,
                              expression: "durationOfSchedule"
                            }
                          ],
                          ref: "inputUsername",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "Username",
                            placeholder: _vm.$t("TOURNAMENT.MINIMUM_OF_PEOPLE"),
                            autocomplete: "off",
                            name: _vm.$t("PLEASE_FILL_OUT_THIS_FIELD"),
                            oninvalid: "this.setCustomValidity(this.name)",
                            oninput: "this.setCustomValidity('')",
                            required: ""
                          },
                          domProps: { value: _vm.durationOfSchedule },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.durationOfSchedule = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "col-1 pt-1" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("TOURNAMENT.HOURS")) + " ")
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-4 col-form-label bold" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("TOURNAMENT.REGISTER_LATE")) +
                              " "
                          )
                        ]
                      ),
                      _c("div", { staticClass: "col-7" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.registerLate,
                              expression: "registerLate"
                            }
                          ],
                          ref: "inputUsername",
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "Username",
                            placeholder: _vm.$t("TOURNAMENT.MAXIMUM_OF_PEOPLE"),
                            autocomplete: "off",
                            name: _vm.$t("PLEASE_FILL_OUT_THIS_FIELD"),
                            oninvalid: "this.setCustomValidity(this.name)",
                            oninput: "this.setCustomValidity('')",
                            required: ""
                          },
                          domProps: { value: _vm.registerLate },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.registerLate = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c("div", { staticClass: "col-1 pt-1" }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("TOURNAMENT.MINS")) + " ")
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group row",
                        staticStyle: { "margin-left": "auto" }
                      },
                      [
                        _c("label", { staticClass: "col-form-label bold" }, [
                          _c("h4", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.$t("TOURNAMENT.ALL_REWARDS")))
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-8" },
                          [
                            _c("v-select", {
                              attrs: {
                                required: "",
                                clearable: false,
                                options: _vm.allRewardOptions,
                                reduce: function(x) {
                                  return x.value
                                },
                                placeholder: _vm.$t("TOURNAMENT.REWARDS")
                              },
                              model: {
                                value: _vm.allReward,
                                callback: function($$v) {
                                  _vm.allReward = $$v
                                },
                                expression: "allReward"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.$t("TOURNAMENT.TOP_REWARDS")) + " ")
                    ])
                  ])
                ]),
                _vm._l(_vm.topRewards, function(r, indexTop) {
                  return _c(
                    "div",
                    { key: "top" + indexTop, staticClass: "row" },
                    [
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-2 col-form-label bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(indexTop + 1) +
                                  " " +
                                  _vm._s(_vm.$t("TOURNAMENT.INDEX_REWARD")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-9" },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  clearable: false,
                                  options: _vm.rewardPercentageOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                model: {
                                  value: r.rewardPT,
                                  callback: function($$v) {
                                    _vm.$set(r, "rewardPT", $$v)
                                  },
                                  expression: "r.rewardPT"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-1" }, [
                            indexTop != 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-red ripple-parent",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteTopReward(indexTop)
                                      }
                                    }
                                  },
                                  [_vm._v(" x ")]
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "row mb-4",
                    staticStyle: { "text-align": "right" }
                  },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info ripple-parent mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.addNewTopReward()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("TOURNAMENT.ADD_ANOTHER_LEVEL")) +
                              " "
                          ),
                          _c("i", { staticClass: "fa-plus" })
                        ]
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.$t("TOURNAMENT.BASE_REWARDS")) + " ")
                    ])
                  ])
                ]),
                _vm._l(_vm.baseRewards, function(baseObj, indexBase) {
                  return _c(
                    "div",
                    { key: "base" + indexBase, staticClass: "row" },
                    [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-4 col-form-label bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(indexBase + 1) +
                                  " " +
                                  _vm._s(_vm.$t("TOURNAMENT.INDEX_REWARD")) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-7" },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  clearable: false,
                                  options: _vm.rewardPercentageOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                model: {
                                  value: baseObj.rewardPT,
                                  callback: function($$v) {
                                    _vm.$set(baseObj, "rewardPT", $$v)
                                  },
                                  expression: "baseObj.rewardPT"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-1" })
                        ])
                      ]),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-4 col-form-label bold" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "TOURNAMENT.NUMBER_OF_PPL_WHO_RECIEVE_A_PRICE"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c("v-select", {
                                attrs: {
                                  required: "",
                                  clearable: false,
                                  options: _vm.rewardPercentageOptions,
                                  reduce: function(x) {
                                    return x.value
                                  }
                                },
                                model: {
                                  value: baseObj.playerPT,
                                  callback: function($$v) {
                                    _vm.$set(baseObj, "playerPT", $$v)
                                  },
                                  expression: "baseObj.playerPT"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-2" }, [
                            indexBase != 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-red ripple-parent",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteBaseReward(indexBase)
                                      }
                                    }
                                  },
                                  [_vm._v(" x ")]
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "row mb-4",
                    staticStyle: { "text-align": "right" }
                  },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info ripple-parent mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.addNewBaseReward()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("TOURNAMENT.ADD_ANOTHER_LEVEL")) +
                              " "
                          ),
                          _c("i", { staticClass: "fa-plus" })
                        ]
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.$t("TOURNAMENT.BLIND_LEVELS")) + " ")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-2 col-form-label bold" },
                        [_vm._v(" " + _vm._s(_vm.$t("TOURNAMENT.ANTES")) + " ")]
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn-group btn-group-toggle d-flex",
                            attrs: {
                              role: "group",
                              "aria-label": "Basic example"
                            }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                class: { active: _vm.activeAnte },
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.setAnte("YES")
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("YES")) + " ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                class: { active: !_vm.activeAnte },
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.setAnte("NO")
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("NO")) + " ")]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row mb-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-12",
                      staticStyle: {
                        "max-height": "500px !important",
                        overflow: "auto",
                        "padding-right": "0"
                      }
                    },
                    [
                      _c("table", { staticClass: "table table-bordered" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("TOURNAMENT.SEQ_NO")))
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("TOURNAMENT.SB")))
                            ]),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("TOURNAMENT.BB")))
                            ]),
                            _vm.activeAnte
                              ? _c("th", { attrs: { scope: "col" } }, [
                                  _vm._v(_vm._s(_vm.$t("TOURNAMENT.ANTE")))
                                ])
                              : _vm._e(),
                            _c("th", { attrs: { scope: "col" } }, [
                              _vm._v(_vm._s(_vm.$t("TOURNAMENT.DURATION")))
                            ]),
                            _c(
                              "th",
                              {
                                staticStyle: { width: "87.7px" },
                                attrs: { scope: "col" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("TOURNAMENT.MANAGE")) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.blindLevels, function(r, index) {
                            return _c("tr", { key: r.id }, [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(" " + _vm._s(index + 1) + " ")
                              ]),
                              _c("th", { attrs: { scope: "row" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: r.smallBlind,
                                      expression: "r.smallBlind"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("TOURNAMENT.SB"),
                                    autocomplete: "off",
                                    oninput: "this.setCustomValidity('')",
                                    required: ""
                                  },
                                  domProps: { value: r.smallBlind },
                                  on: {
                                    keydown: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keypress: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keyup: function($event) {
                                      r.smallBlind = _vm.formatNumber(
                                        $event.target.value
                                      )
                                    },
                                    invalid: function($event) {
                                      return _vm.myCustomValidity(
                                        $event.target,
                                        "Please fill out this field"
                                      )
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        r,
                                        "smallBlind",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("th", { attrs: { scope: "row" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: r.bigBlind,
                                      expression: "r.bigBlind"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("TOURNAMENT.BB"),
                                    autocomplete: "off",
                                    name: _vm.$t("PLEASE_FILL_OUT_THIS_FIELD"),
                                    oninput: "this.setCustomValidity('')",
                                    required: ""
                                  },
                                  domProps: { value: r.bigBlind },
                                  on: {
                                    keydown: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keypress: function($event) {
                                      return _vm.onlyNumber($event)
                                    },
                                    keyup: function($event) {
                                      r.bigBlind = _vm.formatNumber(
                                        $event.target.value
                                      )
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        r,
                                        "bigBlind",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm.activeAnte
                                ? _c("th", { attrs: { scope: "row" } }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: r.ante,
                                          expression: "r.ante"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.$t("TOURNAMENT.ANTE"),
                                        autocomplete: "off",
                                        name: _vm.$t(
                                          "PLEASE_FILL_OUT_THIS_FIELD"
                                        ),
                                        oninput: "this.setCustomValidity('')",
                                        required: ""
                                      },
                                      domProps: { value: r.ante },
                                      on: {
                                        keydown: function($event) {
                                          return _vm.onlyNumber($event)
                                        },
                                        keypress: function($event) {
                                          return _vm.onlyNumber($event)
                                        },
                                        keyup: function($event) {
                                          r.ante = _vm.formatNumber(
                                            $event.target.value
                                          )
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            r,
                                            "ante",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _c("th", { attrs: { scope: "row" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: r.duration,
                                      expression: "r.duration"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t(
                                      "TOURNAMENT.DURATION_PLACEHOLDER"
                                    ),
                                    autocomplete: "off",
                                    oninput: "this.setCustomValidity('')",
                                    required: ""
                                  },
                                  domProps: { value: r.duration },
                                  on: {
                                    invalid: function($event) {
                                      return _vm.myCustomValidity(
                                        $event.target,
                                        "Please fill out this field"
                                      )
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        r,
                                        "duration",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c(
                                "th",
                                {
                                  staticClass: "text-center",
                                  attrs: { scope: "row" }
                                },
                                [
                                  index != 0
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-red ripple-parent",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteBindLevel(index)
                                            }
                                          }
                                        },
                                        [_vm._v(" x ")]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row mb-4",
                    staticStyle: { "text-align": "right" }
                  },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info ripple-parent mr-2",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.addNewBindLevel()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("TOURNAMENT.ADD_ANOTHER_LEVEL")) +
                              " "
                          ),
                          _c("i", { staticClass: "fa-plus" })
                        ]
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          id: "'breadcrumb1",
                          to: { name: "TournamentList" }
                        }
                      },
                      [
                        _c(
                          "button",
                          { staticClass: "btn btn-red ripple-parent mr-2" },
                          [_vm._v(" " + _vm._s(_vm.$t("CANCEL")) + " ")]
                        )
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-yellow ripple-parent mr-2",
                        attrs: { type: "submit", id: "agent-submit" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("OK")) + " ")]
                    )
                  ],
                  1
                )
              ],
              2
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }