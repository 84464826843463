import { Component, Vue } from "vue-property-decorator";
import VueCookies from 'vue-cookies'
import AgentComponent from './view.component.vue';
Vue.use(VueCookies)

@Component({
    components: {
        AgentComponent
    }
})

default class AddAgent extends Vue {

}
export default AddAgent